.header-sep {
    padding-top: 60px;
}

.header {
    position: fixed;
    width: 100%;
    height: 60px;
    z-index: 100;
}

.header .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: .33s;
    cursor: pointer;
}

.header .logo-svg {
    display: inline-block;
    transition: .33s;
}

.header .logo:hover .logo-svg {
    opacity: 0;
}

.header .logo-text {
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 50%;
    width: 0;
    transform: translateX(-50%);
    overflow: hidden;
    transition: .33s ease-in-out;
    white-space: nowrap;
}

.header-active .header .logo-text, .header-active .header .logo-text .blue-text {
    color: #fff;
}

.header .logo:hover .logo-text {
    width: 200px;
}

.header .header-blob {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -100px;
    right: -60px;
    transition: .33s;
}

.header-blob .header-opener {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: #fff;
    bottom: 110px;
    left: 60px;
    border-radius: 50%;
    transition: .33s;
    cursor: pointer;
    display:flex;
	justify-content:center;
	align-items:center;
}

.header .menu-toggle {
	flex:0 0 auto;
	width:40px;
	height:40px;
	cursor:pointer;
	position:relative;
}

.header .hamburger,.cross {
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}

.header .hamburger span {
	display:block;
	width:18px;
	height:2px;
	margin-bottom:3px;
	overflow:hidden;
	position:relative;
}

.header .hamburger span:last-child {
	margin:0;
}

.header .hamburger span:before,.hamburger span:after {
	content:"";
	position:absolute;
	width:100%;
	height:100%;
	background-color: #0099ff;
	transform:translateX(-200%);
	transition:transform ease 300ms;
}

.header .hamburger span:after {
	transform:translateX(0);
}

.header .hamburger span:nth-child(2):before, .hamburger span:nth-child(2):after {
	transition-delay:75ms;
}

.header .hamburger span:last-child:before,.hamburger span:last-child:after {
	transition-delay:150ms;
}

.menu-toggle:hover .hamburger span:before {
	transform:translateX(0);
}

.menu-toggle:hover .hamburger span:after {
	transform:translateX(200%);
}

.header-active .menu-toggle .hamburger span:before {
	transform:translateX(100%);
}

.header-active .menu-toggle .hamburger span:after {
	transform:translateX(200%);
}

.cross span {
	display:block;
	width:18px;
	height:2px;
	background-color:#0099ff;
	transform:translateY(50%) rotate(45deg) scaleX(0);
	transition:transform ease 200ms;
}

.cross span:last-child {
	transform:translateY(-50%) rotate(-45deg) scaleX(0);
}

.header-active .menu-toggle .cross span {
	transition-delay:450ms;
	transform:translateY(50%) rotate(45deg) scaleX(1);
}

.header-active .menu-toggle .cross span:last-child {
	transform:translateY(-50%) rotate(-45deg) scaleX(1);
}

.header-blob .header-opener:hover {
    background-color: #F1F1F1;
}

.header .header-nav {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: -100vh;
    left: 0;
    z-index: -1;
    background-color: #0099ff;
    transition: .33s ease-in-out;
}

.header-active .header .header-nav {
    top: 0;
}

.header-nav .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.header-nav-centered {
    position: relative;
}

.header-nav-centered .left-nav {
    max-width: 50%;
    float: left;
    padding: 0 5%;
}

.left-nav::after {
    position: absolute;
    width: 3px;
    height: 150px;
    background-color: #FFFFFF;
    content: ' ';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-nav-centered .left-nav .left-nav-ul {
    list-style: none;
}

.header-nav-centered .left-nav .left-nav-ul li {
    line-height: 6rem;
    transition: .33s;
}

.header-nav-centered .left-nav .left-nav-ul li a {
    font-size: 6rem;
    letter-spacing: 5px;
    color: #FFFFFF;
    font-weight: 500;
    text-transform: capitalize;
    transition: .33s;
}

.header-nav-centered .left-nav .left-nav-ul li a:hover {
    margin-left: 2rem;
}

.right-nav {
    right: 10%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.right-nav .nav-img-box {
    position: relative;
}

.right-nav .nav-img-box img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.right-nav .nav-socials {
    margin-top: 25px;
}

.right-nav .nav-socials-ul {
    display: inline-flex;
    list-style: none;
}

.right-nav .nav-socials-ul li {
    margin: 10px;
}
.right-nav .nav-socials-ul li img {
    position: relative;
    transition: .33s;
    top: 0;
}

.right-nav .nav-socials-ul li:hover img {
    top: -15px;
    transform: rotate(360deg);
}

@media screen and (max-width: 1500px) {
    .header-nav-centered .left-nav .left-nav-ul li a {
        font-size: 5rem;
    }
    .header-nav-centered .left-nav .left-nav-ul li {
        line-height: 5rem;
    }
}

@media screen and (max-width: 1300px) {
    .header-nav-centered .left-nav .left-nav-ul li a {
        font-size: 4rem;
    }
    .header-nav-centered .left-nav .left-nav-ul li {
        line-height: 4rem;
    }
    .right-nav .nav-img-box img {
        width: 200px;
        height: 200px;
    }
    .right-nav .nav-socials-ul li img {
        width: 35px;
        height: 35px;
    }
    .right-nav {
        right: auto;
        top: auto;
        left: 50%;
        transform: translate(-50%, 50%);
        /* bottom: 0; */
    }

    .header-nav .container {
        height: 90vh;
    }

    .header-nav-centered {
        height: 90vh;
    }
    .header-nav-centered .left-nav {
        position: relative;
        top: 10%;
        float: none;
        text-align: center;
        max-width: 100%
    }
    .header-nav-centered .left-nav .left-nav-ul li a:hover {
        margin-left: 0;
    }
    .left-nav::after {
        content: none;
    }
}