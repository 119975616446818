:root {
    --color-blue: #0099ff;
}

.first-home-wrapper {
    width: 100%;
    height: calc(600px - 60px);
    position: relative;
}

.first-home-wrapper .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.first-home-wrapper .home-wrapper {
    margin: 0 5%;
    position: relative;
}

.first-home-wrapper .big-title-m {
    letter-spacing: 2px;
    margin-top: 10px;
}

.first-home-wrapper .big-home-text {
    float: left;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.first-home-wrapper .big-home-photo {
    position: relative;
    float: right;
    margin-right: 10%;
}

.first-home-wrapper .big-home-photo img {
    right: 0;
    max-height: 400px;
}

.second-home-wrapper.wrapper {
    margin-top: 100px;
}

@media screen and (max-width: 1500px) {  
    .first-home-wrapper .home-wrapper {
        margin: 0;
    }
}

@media screen and (max-width: 1350px) {
    .first-home-wrapper .big-home-photo {
        margin: 0;
    }
}

@media screen and (max-width: 1200px) {
    .container {
      width: 90%;
    }
}

@media screen and (max-width: 1100px) {
    .first-home-wrapper .big-home-photo img {
        max-height: 200px;
    }
}

@media screen and (max-width: 900px) {
    .first-home-wrapper .big-home-photo {
        position: absolute;
        float: none;
        left: 50%;
        transform: translateX(-50%);
        top: -250px;
    }
    .first-home-wrapper .big-home-text {
        position: relative;
        float: none;
        top: 0;
        transform: none;
    }
    .first-home-wrapper {
        padding-top: 250px;
    }
}

@media screen and (max-width: 700px) {
    .first-home-wrapper {
        height: calc(700px - 60px);
    }
}