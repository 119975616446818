.blog-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 50px;
  margin: 0 50px;
  padding-top: 50px;
}

.blog-grid .blog-grid-elem {
  width: 100%;
  height: 200px;
  background-color: #F1F1F1;
  border-radius: 25px;
  position: relative;
}

.blog-grid .blog-grid-elem-container {
  padding: 5%;
}

.blog-grid .blog-grid-elem-container .hashtags {
  position: relative;
  top: 10px;
}


.blog-grid .blog-grid-elem-container .desc {
  position: absolute;
  bottom: 10%;
  width: calc(100% - 10%);
}

.blog-grid .blog-grid-elem-container .desc .text-left {
  float: left;
}

.blog-grid .blog-grid-elem-container .desc .text-right {
  float: right;
}

@media screen and (max-width: 800px) {
  .blog-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 650px) {
  .blog-grid {
    margin: 0;
  }
} 